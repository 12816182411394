import axios from "../../axios-middleware";
import HELPERS from "@/modules/OperatorPanel/helpers";
import i18n from "@/lang/i18n.js";

const state = {
  operator_panel: {
    active_chat: {
      participants: [

      ],
      titleImageUrl:
        "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
      messageList: [

      ],
			client_id: null,
			status_chat: null,
      replyMessageData: null,

      newMessagesCount: 0,
      isChatOpen: false,
      showTypingIndicator: "",
      colors: null,
      availableColors: {
        red: {
          header: {
            bg: "#D32F2F",
            text: "#fff",
          },
          launcher: {
            bg: "#D32F2F",
          },
          messageList: {
            bg: "#fff",
          },
          sentMessage: {
            bg: "#F44336",
            text: "#fff",
          },
          receivedMessage: {
            bg: "#eaeaea",
            text: "#222222",
          },
          userInput: {
            bg: "#fff",
            text: "#212121",
          },
          userList: {
            bg: "#fff",
            text: "#212121",
          },
        },
        green: {
          header: {
            bg: "#388E3C",
            text: "#fff",
          },
          launcher: {
            bg: "#388E3C",
          },
          messageList: {
            bg: "#fff",
          },
          sentMessage: {
            bg: "#4CAF50",
            text: "#fff",
          },
          receivedMessage: {
            bg: "#eaeaea",
            text: "#222222",
          },
          userInput: {
            bg: "#fff",
            text: "#212121",
          },
          userList: {
            bg: "#fff",
            text: "#212121",
          },
        },
        blue: {
          header: {
            bg: "#4e8cff",
            text: "#ffffff",
          },
          launcher: {
            bg: "#4e8cff",
          },
          messageList: {
            bg: "#ffffff",
          },
          sentMessage: {
            bg: "#4e8cff",
            text: "#ffffff",
          },
          receivedMessage: {
            bg: "#eaeaea",
            text: "#222222",
          },
          userInput: {
            bg: "#f4f7f9",
            text: "#565867",
          },
          userList: {
            bg: "#fff",
            text: "#212121",
          },
        },
        dark: {
          header: {
            bg: "#34495e",
            text: "#ecf0f1",
          },
          launcher: {
            bg: "#34495e",
          },
          messageList: {
            bg: "#2c3e50",
          },
          sentMessage: {
            bg: "#7f8c8d",
            text: "#ecf0f1",
          },
          receivedMessage: {
            bg: "#95a5a6",
            text: "#ecf0f1",
          },
          userInput: {
            bg: "#34495e",
            text: "#ecf0f1",
          },
          userList: {
            bg: "#2c3e50",
            text: "#ecf0f1",
          },
        },
      },
      chosenColor: null,
      alwaysScrollToBottom: true,
      messageStyling: true,
      showEdition: false,
      showDeletion: false,
      disableUserListToggle: false,
      placeholder: "Write a message...",
      title: "Chat title",
      showFile: false,
      showEmoji: false,
      showLauncher: true,
      showCloseButton: false,
      quickReplies: [],
      quickReplyCategories: [],
			replies_mode: null,
      isQuickRepliesActive: false,
      isAutoRepliesActive: false,
      quickReplyCategory: null,
      chatStatuses: [],
    },
    chatDisplayMode: "frame", // frame||widget
		chatRoomTransfers: {},
  },
};

const mutations = {
  OP_SET_CHAT_ROOM_MESSAGES(state, payload) {
    const {messages, roomId} = payload

    const userId = state.operator_panel.userInfo.id;
    const options = { messages, userId };

    if (roomId === state.operator_panel.activeChatRoom) {
      state.operator_panel.active_chat.messageList = HELPERS.formatDialogMsgs(options);
    }
  },
	OP_SET_CHAT_ROOM_CLIENT_ID(state, payload) {
		state.operator_panel.active_chat.client_id = payload
	},
	OP_SET_CHAT_ROOM_STATUS(state, payload) {
		state.operator_panel.active_chat.status_chat = payload
	},
  OP_CLEAR_CHAT_ROOM_MESSAGES(state) {
    state.operator_panel.active_chat.messageList = [];
  },
  OP_SET_QUICK_REPLIES(state, list) {
    state.operator_panel.active_chat.quickReplies = list;
  },
  OP_SET_QUICK_REPLY_CATEGORIES(state, list) {
    state.operator_panel.active_chat.quickReplyCategories = list;
  },
  OP_ACTIVATE_QUICK_REPLIES(state, boolean) {
    state.operator_panel.active_chat.isQuickRepliesActive = boolean;
  },
  OP_ACTIVATE_AUTO_REPLIES(state, boolean) {
    state.operator_panel.active_chat.isAutoRepliesActive = boolean;
  },
	OP_SET_REPLIES_MODE(state, value) {
		state.operator_panel.active_chat.replies_mode = value;
	},
  OP_SET_QUICK_REPLY_CATEGORY(state, category) {
    state.operator_panel.active_chat.quickReplyCategory = category;
  },

  OP_SET_CHAT_STATUSES(state, statuses) {
    state.operator_panel.active_chat.chatStatuses = statuses;
  },

  OP_SET_REPLY_MESSAGE_DATA(state, data) {
    state.operator_panel.active_chat.replyMessageData = data;
  },
	OP_SET_CHAT_ROOM_TRANSFERS(state, data) {
		state.operator_panel.chatRoomTransfers = data;
	}
};

const actions = {
  async OpAxiosGetChatRoomMessages({ commit }, chatRoomId) {
    const roomId = parseInt(chatRoomId);

    if (!roomId) return [];

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${roomId}/messages/list/`;

    const { messages, status_chat }  = await axios
      .get(url)
      .then((response) => {
        return {
					messages: response.data?.data?.messages || [],
					status_chat: response.data?.data?.status,
				}
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return [];
      });

    commit("OP_SET_CHAT_ROOM_MESSAGES", {messages, roomId});
		commit("OP_SET_CHAT_ROOM_STATUS", status_chat);
  },
	async OpAxiosGetChatRoomClientId({ commit }, chatRoomId) {
		const roomId = parseInt(chatRoomId);

		if (!roomId) return [];

		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${roomId}/messages/list/`;

		const {client_id, status_chat} = await axios
			.get(url)
			.then((response) => {
				const participants = response.data?.data?.participants || [];
				const isClient = (participant) => participant.type === 'client';

				return {
					client_id: participants.find(isClient)?.client_id || {},
					status_chat: response.data?.data?.status,
				}
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
				return [];
			});


		commit("OP_SET_CHAT_ROOM_CLIENT_ID", client_id);
		commit("OP_SET_CHAT_ROOM_STATUS", status_chat);
	},
	async OpAxiosGetChatRoomMessagesLimit({ _ }, chatRoomId) {
		const roomId = parseInt(chatRoomId);

		if (!roomId) return [];

		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/${roomId}/messages/list/?limit=1`;

		return await axios
			.get(url)
			.then((response) => {
				return response.data?.data?.messages
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
				return [];
			});
	},
  async OpAxiosSendMessageToChat({ dispatch }, messageObj) {
    dispatch("updateAjaxDialog", [true, true]);

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/message/add/`;

    let request;

    switch (messageObj.message_type) {
      case "file":
        request = HELPERS.toFormData(messageObj);
        break;

      default:
        request = messageObj;
        break;
    }

    const success = await axios
      .post(url, request)
      .then((response) => {
        dispatch("updateAjaxDialog", [false, false]);
        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
            i18n.t("common.error"),
            i18n.t("modules.op.texts.send_error"),
        ]);
        return false;
      });

    return success;
  },

  async OpAxiosLeaveChat({ dispatch }, { chatRoomId, participantId, ...restData }) {
    // participantId - from chat partcipants - current chat my participant id
    dispatch("updateAjaxDialog", [true, true]);

    const roomId = parseInt(chatRoomId);

    const request = {
      chat_room_id: roomId,
      participants: [participantId],
      ...restData,
    };

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/participants/remove/`;

    const success = await axios
      .post(url, request)
      .then((response) => {
        dispatch("updateAjaxDialog", [false, false]);
        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_exit")]);
        return false;
      });

    return success;
  },

  async OpAxiosConnectToChat({ dispatch }, query) {
    dispatch("updateAjaxDialog", [true, true]);

    const { roomId, userId, forceConnected } = query;
    const chatRoomId = parseInt(roomId);

    const request = {
      chat_room_id: chatRoomId,
      participants: [
        {
          type: "user",
          user_id: userId,
        },
      ],
    };

    if (forceConnected) {
      request.force_connected = forceConnected;
    }

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/participants/add/`;

    const responseData = await axios
      .post(url, request)
      .then((response) => {
        dispatch("updateAjaxDialog", [false, false]);
        return response.data;
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return err.response.data;
      });

    return responseData;
  },

  async opGetQuickReplies({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/quick_replies/list/`;

    const quickReplies = await axios({
      url,
      method: 'GET',
    })
      .then((response) => {
        return response.data?.data?.quick_replies || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return [];
      });

    commit("OP_SET_QUICK_REPLIES", quickReplies);
  },

  async opGetQuickReplyCategories({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/quick_reply_categories/list/`;

    const quickReplyCategories = await axios({
      url,
      method: 'GET',
    })
      .then((response) => {
        return response.data?.data?.categories || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return [];
      });

    commit("OP_SET_QUICK_REPLY_CATEGORIES", quickReplyCategories);
  },

  opActivateQuickReplies({ commit }, boolean) {
    commit("OP_ACTIVATE_QUICK_REPLIES", boolean);
  },

  opActivateAutoReplies({ commit }, boolean) {
    commit("OP_ACTIVATE_AUTO_REPLIES", boolean);
  },

	opSetRepliesMode({ commit, dispatch }, value) {
		if (value === 'quick-replies') {
			dispatch('opActivateQuickReplies', true)
			dispatch('opActivateAutoReplies', false);
		} else if (value === 'auto-replies') {
			dispatch('opActivateQuickReplies', false)
			dispatch('opActivateAutoReplies', true);
		} else {
			dispatch('opActivateQuickReplies', false)
			dispatch('opActivateAutoReplies', false);
		}

		commit("OP_SET_REPLIES_MODE", value);
	},

  opSetQuickReplyCategory({ commit }, category) {
    commit("OP_SET_QUICK_REPLY_CATEGORY", category);
  },

  async opAxiosGetChatStatuses ({commit}) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_statuses/list/`;

    const chatStatuses = await axios({
      url,
      method: 'GET',
    })
      .then((response) => {
        return response.data?.data?.statuses || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_get")]);
        return [];
      });

    commit("OP_SET_CHAT_STATUSES", chatStatuses);
  },

  opSetReplyMessageData({ commit }, data) {
    commit('OP_SET_REPLY_MESSAGE_DATA', data)
  },
	opSetTransferDisabled({ commit }, value) {
    commit('OP_SET_TRANSFER_DISABLED', value)
  },
	setChatRoomTransfers({commit}, data) {
		commit('OP_SET_CHAT_ROOM_TRANSFERS', data)
	},

	opClearActiveChat({dispatch, commit}, destroy = null) {
		dispatch('opSetTopSidebarOpen', false);
		dispatch('opSetRightSidebarOpen', false);

		(destroy)
			? dispatch('opClearActiveChatRoomOnDestroy', null)
			: dispatch('opSetActiveChatRoom', null)

		commit('OP_CLEAR_CHAT_ROOM_MESSAGES');
	},
};

const getters = {
  operator_panel_active_chat: (state) => {
    return state.operator_panel.active_chat;
  },

  opIsChatFrame: (state) => state.operator_panel.chatDisplayMode === "frame",

  opQuickReplies: (state) => {
    return state.operator_panel.active_chat.quickReplies;
  },

  opQuickReplyCategories: (state) => {
    return state.operator_panel.active_chat.quickReplyCategories;
  },

  opIsQuickRepliesActive: (state) => {
    return state.operator_panel.active_chat.isQuickRepliesActive;
  },

  opIsAutoRepliesActive: (state) => {
    return state.operator_panel.active_chat.isAutoRepliesActive;
  },
	opGetRepliesMode: (state) => {
		return state.operator_panel.active_chat.replies_mode;
	},

  opQuickReplyCategory: (state) => {
    return state.operator_panel.active_chat.quickReplyCategory;
  },

  opChatStatuses: (state) => {
    return state.operator_panel.active_chat.chatStatuses;
  },

  opReplyMessageData: (state) => {
    return state.operator_panel.active_chat.replyMessageData;
  },
	opGetChatRoomClientId: (state) => state.operator_panel.active_chat.client_id,
	opGetChatRoomStatus: (state) => state.operator_panel.active_chat.status_chat,
	opChatRoomTransfers: (state) => state.operator_panel.chatRoomTransfers,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
